
.mt-60 {
    margin-top: 60px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt--20 {
    margin-top: -20px !important;
}
.ml--20 {
    margin-left: -20px !important;
}
.ml-40 {
    margin-left: 40px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mt-40 {
    margin-top: 40px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-60 {
    margin-bottom: 60px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}
.mr-40 {
    margin-right: 40px !important;
}
.mr-45 {
    margin-right: 45px !important;
}


.pl-30 {
    padding-left: 30px !important;
}
.pr-30 {
    padding-right: 30px !important;
}
.pr-40 {
    padding-right: 40px !important;
}
.pr-45 {
    padding-right: 45px !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.pb-60 {
    padding-bottom: 60px !important;
}
.pb-40 {
    padding-bottom: 40px !important;
}
.pt-60 {
    padding-top: 60px !important;
}