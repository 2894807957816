@media (max-width: 992px) {
    .title-page {
        font-size: 36px;
    }
    .list-media-top {
        margin-top: 30px;
        row-gap: 30px;
        column-gap: 30px;
    }
    .title-section {
        font-size: 34px;
    }
    .table-wrap {
        overflow: auto;
        .title-col {
            font-size: 18px;
        }
    }
    .list-advantages {
        padding-top: 0 !important;
        .item-advantage {
            grid-template-columns: 1fr !important;
            &.active {
                .body-advantage {
                    height: auto;
                }
            }
        }
        .head-advantage {
            .val {
                font-size: 36px !important;
                
            }
            .desc {
                font-size: 18px !important;
            }
        }
        .head-chart {
            line-height: 1;
            span {
                font-size: 36px !important;
            }
        }
        .body-advantage {
            width: 100% !important;
            position: static !important;
            margin-top: 20px;
            height: 0;
        }
    }
}

@media (max-width: 768px) {
    header.header {
        .btn-open-menu-wrap,.btn-close-menu {
            display: block;
        }
        .logo {
            width: 60px;
            height: 60px;
        }
    }
    .main-menu-wrap {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        background: #fff;
        height: 100vh;
        z-index: 30;
        transition: .8s;
        padding: 40px 0;
        &.active {
          left: 0;
        }
        .btn-close-menu {
            position: absolute;
            right: 20px;
            top: 20px;
            border: none;
            background: transparent;
            padding: 0;
        }
        .main-menu {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 40px;
            font-size: 24px;
            align-items: center;
        }
    }
    .first-window {
        margin-bottom: 40px;
        padding-bottom: 40px;
        padding-top: 150px;
        &.contact {
            padding-bottom: 60px;
        }
        .logo-style {
            display: none;
        }
    }
    .contact-us-contact {
        padding-left: 0;
        margin-top: 40px;
    }
    .contact-us-content {
        padding: 20px;
        .title-contact-section:before {
            height: 100px;
        }
    }
    .list-solutions {
        .item-solution {
            width: 100%;
            height: 120px;
            &.active {
                width: 100%;
                height: 80vh;
            }
            .title {
                transform: rotate(0deg);
                font-size: 26px;
                text-align: center;
                white-space: wrap;
            }
        }
    }
    .list-clients {
        .item-client {
            .img-wrap {
                padding: 0 30px;
            }
        }
    }
    .list-reviews-wrap {
        max-height: 900px;
    }
    .list-advantages {
        padding-left: 0;
        .head-advantage {
            padding-left: 85;
            padding-right: 20px;
            .val {
                font-size: 48px;
            }
        }
    }
    .advantage-image, .contact-us-image {
        img {
            height: auto;
        }
    }
    .list-adjust {
        margin-bottom: 40px;
    }
    .adjust-image {
        height: 80vh !important;
        width: 100%;
        margin-bottom: 0;
        margin-left: 0 !important;
    }
    .members-section {
        .list-members {
            grid-template-columns: 1fr !important;
        }
    }
    .about-us-section {
        .left-col {
            margin-bottom: 30px;
        }
    }
    .contact-us-contact {
        padding-left: 0 !important;
    }
    .title-page.default {
        font-size: 36px;
    }
    .text-content {
        h2 {
            font-size: 36px !important;
        }
        h1 {
            font-size: 36px !important;
            line-height: 1.4;
        }
    }
    .contact-us-section {
        margin-top: -100px;
        .title-contact-section {
            font-size: 36px;
        }
        .item-contact {
            width: 90%;
        }
    }
    .list-plans {
        .list-options {
            padding: 15px 0 !important;
        }
        .item-plan-wrap {
            &.enterprise {
                .btn-theme-wrap {
                    position: static !important;
                    margin-top: 100px;
                }
            }
        }
    }

    .advantage-clients {
        height: auto !important;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 15px;
        row-gap: 30px;
        column-gap: 30px;
        div {
            width: 100% !important;
            position: static !important;
        }
    }

    .advantage-language {
        .earth-ico {
            position: absolute;
            height: 100%;
            width: 100%;
        }
        .list-languages {
            position: relative;
            z-index: 1;
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 20px;
            padding: 0 15px;
            .item-language {
                position: relative;
                width: 100% !important;
                left: auto !important;
                right: auto !important;
                bottom: auto !important;
                top: auto !important;
            }
        }
    }

    footer.footer {
        .menu-footer {
            margin: 35px 0;
            justify-content: center;
        }
        .subscribe {
            grid-template-columns: 1fr !important;
            row-gap: 15px;
            width: calc(100vw - 30px);
        }
        .logo-footer {
            width: 50vw;
        }
    }
}