
@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-Light.ttf);
    font-style: normal;
    font-display: swap;
    font-weight: 300;
}
@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-Regular.ttf);
    font-style: normal;
    font-display: swap;
    font-weight: 400;
}
@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-Medium.ttf);
    font-style: normal;
    font-display: swap;
    font-weight: 500;
}
@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-SemiBold.ttf);
    font-style: normal;
    font-display: swap;
    font-weight: 600;
}
@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-Bold.ttf);
    font-style: normal;
    font-display: swap;
    font-weight: 700;
}