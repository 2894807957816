
@import 'base/reset';
@import 'base/grid';
@import 'base/fonts';
@import 'base/keyframes';
@import 'base/indents';



body {
  font: 400 16px 'Gilroy', sans-serif;
  line-height: 1.2;
  color: #001B29;
}
html {
  overflow-x: hidden;
}
button,svg,path,circle,input[submit],a {
  transition: .3s;
}

button,input {
  font: 400 14px 'Gilroy', sans-serif;
  &[type="submit"] {
    border: none;
  }
}
button[type="submit"] {
  .btn-name {
    line-height: 1.4;
  }
}


header.header {
  padding: 16px 0;
  position: relative;
  z-index: 2;
  .main-menu {
    display: flex;
    list-style: none;
    column-gap: 5vw;
    justify-content: center;
    a {
      color: #001B29;
      font-weight: 500;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        transition: .4s;
        position: absolute;
        bottom: -5px;
        left: 0;
        background: #51817d;
      }
      &:hover {
        color: #51817d;
        &:before {
          width: 100%;
        }
      }
    }
  }
  .logo {
    width: 83px;
  }
  .btn-open-menu-wrap,.btn-close-menu {
    display: none;
  }
  
  .btn-open-menu {
    display: flex;
    width: 30px;
    height: 15px;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    background: transparent;
    border: none;
    span {
      height: 2px;
      width: 100%;
      background: #000;
      display: block;
    }
  }

  .multilang-wrap {
    width: 83px;
    justify-content: flex-end;
    display: flex;
    position: relative;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 7px;
    z-index: 9;
    transition: .2s;
    button {
      display: flex;
      align-items: center;
      column-gap: 5px;
      padding: 0;
      background: transparent;
      border: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 0;
    }
    &.active {
      box-shadow: 0 0 5px #ccc;
      background: #fff;
      .multilang-dropdown {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .multilang-dropdown {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    padding: 7px;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px #ccc;
    row-gap: 10px;
    background: #fff;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    transition: .2s;
    a {
      color: #000;
      &:hover,&.active {
        color: #51817d;
      }
    }
  }
}

.first-window {
  padding: 200px 0 130px;
  margin-bottom: 80px;
  position: relative;
  margin-top: -120px;
  overflow: hidden;
  &.home {
    padding-bottom: 58px;
  }
  &.contact {
    padding-bottom: 280px;
    .logo-style {
      &.left {
        bottom: auto;
        top: -10%;
        left: 10%;
        width: 176px;
      }
      &.right {
        right: 10%;
        top: auto;
        bottom: -40%;
      }
    }
  }
  &.about-product {
    
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .list-media-top {
    display: grid;
    column-gap: 16px;
    row-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    .item-media {
      position: relative;
      aspect-ratio: 1;
      cursor: pointer;
      &:hover {
        .print {
          opacity: 0;
        }
        .original {
          opacity: 1;
        }
      }
      img,video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .4s;
        &.original {
          opacity: 0;
        }
      }
    }
  }
  .container {
    position: relative;
    .row {
      position: relative;
      z-index: 1;
    }
  }
  .logo-style {
    position: absolute;
    &.left {
      bottom: -10%;
      left: 15px;
    }
    &.right {
      right: 15px;
      top: -10%;
    }
  }
}
.bg-light {
  background: #F4FAF9;
}

.title-page {
  font-size: 58px;
  margin-bottom: 40px;
  &.default {
    color: #3E5F5D;
    font-weight: 600;
    font-size: 56px;
  }
  span {
    font-weight: 700;
    color: #3E5F5D;
  }
}

.desc-first-window {
  font-weight: 500;
  margin-bottom: 45px;
  line-height: 1.8;
}

.btn-theme {
  display: inline-flex;
  position: relative;
  color: #fff;
  background: #81A8A5;
  border-radius: 40px;
  padding: 13px 30px 17px;
  align-items: center;
  overflow: hidden;
  .ico {
    vertical-align: middle;
    margin-left: 15px;
    position: relative;
  }
  .btn-name-wrap {
    height: 18px;
    display: inline-flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-direction: column;
  }
  .btn-name {
    display: block;
    transition: .6s;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 20px);
    aspect-ratio: 1/1;
    background: #3E5F5D;
    border-radius: 50%;
    left: -10px;
    top: 100%;
    transition: .8s;
  }
  &:hover {
    .btn-name {
      transform: translateY(-20px);
    }
    &:before {
      transform: translateY(calc(-50% - 20px));
    }
    
  }
}

.title-section {
  font-size: 48px;
  margin-bottom: 48px;
  color: #2B414D;
  span {
    font-weight: 600;
  }

  .ico-title {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      position: absolute;
      top: -3px;
      left: -3px;
      background: #DBE6E5;
      z-index: -1;
    }
  }
}

.breadcrumbs {
  margin-top: 30px;
  a {
    color: #3E5F5D;
    &:after {
      content: '/';
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }
  }
  span {
    color: #CCD1D4;
  }
}
.contact-us-section {
  padding: 24px;
  box-shadow: 0px 4px 15px rgba(8, 15, 52, 0.08);
  box-shadow: 0 4px 15px rgba(8, 15, 52, .08);
  margin-top: -200px;
  z-index: 1;
  position: relative;
  background: #fff;
  border-radius: 8px;
}
.contact-us-content {
  padding: 40px 90px;
  border-radius: 8px;
  background: #000;
  color: #fafaf9;
  background: #5D8F8B;
  
  .item-contact {
    width: 71%;
    margin: 30px auto;
  }
  .title-contact-section {
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 70px;
    position: relative;
    padding-left: 30px;
    span {
      position: relative;
    }
    &:before {
      content: '';
      display: block;
      width: 85px;
      height: 205px;
      border-radius: 6px;
      background: #81A8A5;
      position: absolute;
      top: -7px;
      left: -7px;
      z-index: 0;
    }
  }
  a {
    color: #fafaf9 !important;
  }
}

.list-adjust {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
  column-gap: 16px;
  row-gap: 16px;
  a.item-adjust {
    padding-bottom: 100px;
    &:hover {
      background: #5D8F8B;
      .title,.text,.link {
        color: #F4FAF9;
      }
      .ico-top {
        svg {
          filter: brightness(0) invert(1);
        }
        &:before {
          width: 64px;
          height: 158px;
          background: #81A8A5;
        }
      }
      .link {
        &:before {
          width: calc(100% - 25px);
        }
        .ico {
          filter: brightness(0) invert(1);
        }
      }
    }
    .ico-top {
      &:before {
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        background: #DBE6E5;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: .8s;
      }
    }
  }
  .item-adjust {
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px #c2e6e0;
    position: relative;
    transition: .4s;
    background: #fff;
    .ico-top {
      padding: 5px;
      position: relative;
      margin-bottom: 24px;
      margin-left: -5px;
      display: inline-flex;
      svg {
        transition: .2s;
        position: relative;
        z-index: 2;
      }
      &:before {
        content: '';
        display: block;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        background: #DBE6E5;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        transition: .8s;
      }
    }
    .title {
      font-size: 24px;
      font-weight: 500;
      padding-right: 40px;
      margin-bottom: 24px;
      color: #001B29;
      min-height: 57px;
      transition: .2s;
      position: relative;
      z-index: 2;
    }
    .text {
      color: #556770;
      line-height: 1.6;
      transition: .2s;
      position: relative;
      z-index: 2;
    }
    .link {
      position: absolute;
      bottom: 40px;
      left: 40px;
      line-height: 0;
      color: #001B29;
      font-size: 14px;
      transition: .2s;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 1px;
        height: 1px;
        background: #F4FAF9;
        transition: .8s;
        width: 0;
      }
      .ico {
        vertical-align: middle;
        padding: 0;
        display: inline-block;
        margin: 0;
        transition: .2s;
      }
    }
  }
}

.adjust-image {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 57.12%, rgba(0, 0, 0, 0.4) 100%);
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .btn-theme {
    margin-bottom: 80px;
    position: relative;
    z-index: 3;
  }
}

.list-solutions {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 16px;
  .item-solution {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    height: 80vh;
    width: calc(16.66% - 14px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s;
    cursor: pointer;
    &.active {
      width: calc(50% - 14px);
      .title {
        transform: rotate(0deg);
      }
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .title {
      color: #F2F3F4;
      font-size: 32px;
      font-weight: 500;
      transform: rotate(-90deg);
      z-index: 2;
      white-space: nowrap;
      transition: .4s;
    }
  }
}
.advantage-image,.contact-us-image {
  border-radius: 8px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.list-advantages {
  padding-top: 20px;
  position: relative;
  .item-advantage {
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 5fr 7fr;
    column-gap: 74px;
    &.active {
      .body-advantage {
        opacity: 1;
        height: auto;
        visibility: visible;
      }
      .head-advantage-wrap {
        color: #F4FAF9;
        background: #5D8F8B;
        .ico {
          svg {
            filter: brightness(0) invert(1);
          }
          &:before {
            background: #81A8A5;
            height: 80px;
          }
        }
      }
      .advantage-clients {
        div {
          transform: scale(1);
        }
      }
      .advantage-language {
        .item-language {
          transform: scale(1);
        }
      }
    }
    #advantage-1 {
      opacity: 0;
      transition: .4;
    }
  }
  .head-chart {
    margin-bottom: 20px;
    font-weight: 500;
    color: #001B29;
    font-size: 28px;
    text-align: center;
    span {
      font-weight: 500;
      font-size: 56px;
      color: #3E5F5D;
      vertical-align: middle;
      margin-right: 30px;
    }
    span.ico {
      vertical-align: middle;
      margin-left: 10px;
      line-height: 0;
      margin-right: 0;
    }
  }
  .head-advantage-wrap {
    cursor: pointer;
    padding: 30px;
    border-radius: 8px;
    color: #3E5F5D;
    box-shadow: 0px 4px 15px rgba(8, 15, 52, 0.08);
    padding-left: 85px;
    position: relative;
    background: #fff;
    transition: .1s;
    &:hover {
      color: #F4FAF9;
      background: #5D8F8B;
      .ico {
        svg {
          filter: brightness(0) invert(1);
        }
        &:before {
          background: #81A8A5;
          height: 80px;
        }
      }
    }
    .val {
      font-size: 56px;
      font-weight: 500;
      
    }
    .desc {
      font-size: 24px;
      margin-top: 15px;
    }
    .ico {
      position: absolute;
      top: 40px;
      left: 20px;
      &:before {
        content: '';
        width: 36px;
        height: 36px;
        background: #DBE6E5;
        border-radius: 2px;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        margin: auto;
        transition: .4s;
      }
      svg {
        position: relative;
        transition: .4s;
      }
    }
  }
  .body-advantage {
    color: #556770;
    line-height: 1.6;
    font-size: 18px;
    transition: height 0.35s ease-in-out;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 55%;
    transition: .2s;
    height: 0;
    ul,ol {
      padding-left: 25px;
    }
    strong {
      color: #001B29;
      font-weight: 500;
    }
  }
}
.list-plans {
  .item-plan-wrap {
    padding-bottom: 16px;
    height: 100%;
    &.enterprise {
      .name-plan {
        color: #3E5F5D;
        font-weight: 600;
        font-size: 56px;
        margin-top: 65px;
      }
      .btn-theme-wrap {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        text-align: center;
      }
      .btn-theme {
        background: transparent;
        color: #001B29;
        font-size: 24px;
        .btn-name-wrap {
          height: 25px;
        }
        .ico {
          transition: .4s;
        }
        &:hover {
          color: #fff;
          .btn-name {
            transform: translateY(-30px);
          }
          .ico {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
  .item-plan {
    padding: 40px 24px 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(8, 15, 52, 0.08);
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  .logo-style {
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
  }
  .name-plan {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
  }
  .price-plan {
    color: #3E5F5D;
    font-weight: 600;
    font-size: 56px;
    text-align: center;
    position: relative;
    margin-bottom: 10px;
    .currency {
      display: inline-block;
      font-size: 24px;
      vertical-align: top;
      margin-right: 8px;
    }
  }
  .per-time {
    font-size: 24px;
    color: #3E5F5D;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
  }
  .item-accordeon {
    padding: 10px 15px;
    background: #DBE6E5;
    border-radius: 8px;
    margin: 16px 0;
    .ico-caret {
      position: absolute;
      right: 0;
      top: 1px;
      line-height: 0;
      transition: .2s;
    }
    .title {
      font-weight: 700;
      position: relative;
      cursor: pointer;
      transition: .2s;
      &:hover {
        opacity: .7;
      }
      &.active {
        .ico-caret {
          transform: rotate(180deg);
        }
      }
    }
    .text {
      padding-top: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.6;
      color: #2B414D;
      max-height: 0;
      transition: .4s;
      overflow: hidden;
      &.active {
        max-height: 100px;
      }
    }
  }
  .list-options {
    padding: 15px;
    margin-top: 30px;
    border-top: 1px solid #B7CDCB;
    .item-option {
      position: relative;
      padding: 10px 10px 10px 40px;
      .ico {
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
  }
}

.advantage-clients {
  position: relative;
  height: 400px;
  div {
    position: absolute;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 4px 15px rgba(8, 15, 52, 0.08);
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    transition: 4s ease-out 0ms;
    img {
      max-height: 70%;
    }
    &:nth-child(1) {
      top: 80px;
      left: 33%;
      width: 15%;
    }
    &:nth-child(2) {
      top: 0;
      right: 15px;
      width: 24%;
    }
    &:nth-child(3) {
      top: 0;
      left: 15px;
      width: 24%;
    }
    &:nth-child(4) {
      bottom: 15px;
      left: 15px;
      width: 18%;
    }
    &:nth-child(5) {
      top: 0;
      left: 55%;
      width: 10%;
    }
    &:nth-child(6) {
      bottom: 15px;
      left: 45%;
      width: 17%;
    }
    &:nth-child(7) {
      bottom: 141px;
      right: 26%;
      width: 20%;
    }
    &:nth-child(8) {
      bottom: 30px;
      right: 15px;
      width: 20%;
    }
    &:nth-child(9) {
      bottom: 82px;
      left: 26%;
      width: 13%;
    }
  }
}

#advantage-2 {
  overflow:visible;
}

.advantage-language {
  position: relative;
  padding: 40px 0;
  .earth-ico {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item-language {
    padding: 15px;
    border-radius: 15px;
    color: #fff;
    width: 42%;
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    transform: scale(0);
    transition: .8s;
    &.dark {
      background: #81A8A5;
      .ico {
        position: absolute;
        bottom: -6px;
        right: -9px;
      }
    }
    &.light {
      background: #B7CDCB;
      .ico {
        position: absolute;
        bottom: -6px;
        left: -9px;
      }
    }
    &:nth-child(1) {
      right: 40px;
      top: 0;
      transition-delay: .1s;
    }
    &:nth-child(2) {
      left: 15px;
      top: 11%;
      transition-delay: .3s;
    }
    &:nth-child(3) {
      right: 15px;
      top: 22%;
      transition-delay: .5s;
    }
    &:nth-child(4) {
      left: 40px;
      top: 33%;
      transition-delay: .7s;
    }
    &:nth-child(5) {
      right: 40px;
      top: 44%;
      transition-delay: .9s;
    }
    &:nth-child(6) {
      left: 15px;
      top: 55%;
      transition-delay: 1.1s;
    }
    &:nth-child(7) {
      left: 20%;
      top: 75%;
      width: 60%;
      transition-delay: 1.3s;
    }
  }
}

.logo-style {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 10%;
  column-gap: 10%;
  width: 216px;
  transition: .2s;
  span {
    border-radius: 2px;
    background: #DBE6E5;
    box-shadow: 0px 3px 11px rgba(8, 15, 52, 0.08);
    aspect-ratio: 1;
    transition: .2s;
    &:nth-child(4),&:nth-child(5),&:nth-child(8) {
      box-shadow: none;
      background: #81A8A5;
    }
  }
}

.list-clients-wrap {
  overflow: hidden;
  position: relative;
  max-height: 300px;
  &:after,&:before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    width: 100px;
    z-index: 2;
  }
  &:before {
    background: linear-gradient(to left, transparent 0, #f4faf9 100%);
    left: 0;
  }
  &:after {
    background: linear-gradient(to right , transparent 0, #f4faf9 100%);
    right: 0;
  }
  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
            transition-timing-function: linear !important;
  }
  .swiper-slide img{
    max-width: 100%;
  }
}
.list-clients {
  display: flex;
  padding: 30px 0 50px;
  .item-client {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-wrap {
    height: 170px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .name {
    font-weight: 500;
  }
}

.list-reviews-wrap {
  overflow: hidden;
  padding-bottom: 100px;
  position: relative;
  max-height: 700px;
  .item-review-wrap {
    padding: 15px;
    transform: scale(.8);
    opacity: .4;
    transition: 1s;
    &.swiper-slide-active {
      opacity: 1;
      transform: scale(1);
    }
  }
  .item-review {
    padding: 30px 25px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(8, 15, 52, 0.08);
  }
  .avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .user-name {
    margin-bottom: 10px;
  }
  .user-position {
    color: #848484;
    font-size: 12px;
  }
  .user-review {
    margin: 25px 0 30px;
    line-height: 1.6;
    font-size: 14px;
  }
  .rating-wrap {
    display: flex;
    justify-content: flex-end;
  }
  .rating {
    padding: 6px 10px;
    background: #f4faf9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .slide-prev,.slide-next {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DBE6E5;
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    transition: .2s;
    &:hover {
      background: #3E5F5D;
      svg {
        filter: brightness(0) invert(1);
      }
    }
    svg {
      transition: .2s;
    }
  }
  .slide-prev {
    left: calc(50% - 70px);
  }
  .slide-next {
    left: calc(50% + 14px);
  }
}

.table-wrap {
  border-radius: 4px;
  border: 1px solid #DBE6E5;
  table {
    width: 100%;
    border-spacing: 8px;
    border-collapse: separate;
  }
  .bg-dark {
    background: #B7CDCB;
  }
  .title-col {
    font-size: 24px;
    font-weight: 600;
  }
  td {
    padding: 9px;
    border: 1px solid #B7CDCB;
    background: #F4FAF9;
    border-radius: 4px;
    vertical-align: middle;
    &:first-child {
      padding-left: 15px;
      &:before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #3E5F5D;
        margin-right: 15px;
        vertical-align: middle;
      }
    }
    &.bg-dark {
      &:before {
        content: none;
      }
    }
  }
}

.input-style {
  margin-bottom: 16px;
  display: block;
  background: #fff;
  border: 1px solid #CFCFCF;
  border-radius: 30px;
  padding: 20px;
  width: 100%;
  &:focus {
    border-color: #3E5F5D;
  }
}
.item-contact {
  position: relative;
  padding-left: 30px;
  margin-top: 20px;
  line-height: 1.6;
  a {
    color: #001B29;
    font-size: 14px;
    &:hover {
      color: #72a29f;
    }
  }
  .ico {
    position: absolute;
    left: 0;
    top: 2px;
  }
}

.contact-us-contact {
  padding-left: 60px;
}
.about-us-section {
  margin-bottom: 60px;
  .left-col {
    padding-right: 30px;
    p {
      line-height: 1.4;
    }
  }
  .btn-theme {
    margin-top: 30px;
  }
}
.members-section {
  .list-members {
    margin: 30px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
    text-align: center;
  }
  .map-member {
    margin: 40px 0;
  }
  .member-item {
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(8, 15, 52, 0.08);
    .image {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      color: #001B29;
      font-size: 24px;
      font-weight: 500;
      margin: 20px 0;
    }
    .address {
      color: #556770;
      font-size: 14px;
      .ico {
        vertical-align: middle;
      }
    }
    .text {
      color: #556770;
      font-size: 16px;
      margin-top: 20px;
      line-height: 1.6;
    }
  }
}
.list-cats {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 12px;
  margin-bottom: 30px;
  padding-left: 0 !important;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 15px;
  list-style: none;
  margin: 50px 0 30px;
  &::-webkit-scrollbar {
    height: 8px !important;
  }

  &::-webkit-scrollbar-track {
    background: #F2F3F4;
  }

  &::-webkit-scrollbar-thumb {
    background: #556770;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  li {
    padding: 0 !important;
    flex: none;
    &:first-child {
      a {
        padding: 20px 40px;
      }
    }
  }
  a {
    white-space: nowrap;
    padding: 20px;
    background: #F2F3F4;
    border-radius: 8px;
    font-size: 24px;
    color: #001B29;
    font-weight: 500;
    border: 1px solid #F2F3F4;
    display: block;
    .ico {
      vertical-align: middle;
      margin-right: 7px;
      line-height: 1;
    }
    &:hover,&.active {
      background: #F4FAF9;
      border-color: #5D8F8B;
    }
  }
}
.post-item {
  margin: 20px 0;
  .thumb {
    height: 230px;
    border-radius: 16px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .2s;
    }
    a {
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  .name {
    font-weight: 600;
    margin: 25px 0 15px;
    line-height: 1.2;
    a {
      color: #001B29;
      font-size: 24px;
      &:hover {
        color: #3E5F5D;
      }
    }
  }
  .excerpt {
    color: #001B29;
    margin-bottom: 15px;
  }
  .link-more {
    color: #001B29;
    font-size: 14px;
    &:hover {
      color: #3E5F5D;
      .ico {
        transform: rotate(0);
      }
    }
    .ico {
      vertical-align: middle;
      margin-left: 10px;
      transform: rotate(-45deg);
      line-height: 1;
      display: inline-block;
      transition: .2s;
    }
  }
}
.pagination {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    color: #001B29;
    font-weight: 600;
    font-size: 16px;
    background: #f4faf9;
    border: 1px solid #f2f3f4;
    &:hover {
      color: #fff;
      background: #5d8f8b;
      svg {
        filter: brightness(0) invert(1);
      }
    }
  }
}
.text-content {
  padding: 30px 0;
  line-height: 1.6;
  h1 {
    font-weight: 600;
    font-size: 48px;
    color: #3E5F5D;
    margin-bottom: 30px;
    line-height: 1.2;
  }
  h2 {
    margin-bottom: 20px;
    font-size: 48px;
    font-weight: 500;
    line-height: 1.2;
    img {
      vertical-align: middle;
    }
  }
  h4 {
    font-size: 24px;
    font-weight: 600;
  }
  h3 {
    font-size: 32px;
    font-weight: 600;
  }
  p {
    margin-bottom: 20px;
  }
  .alignright {
    float: right;
    margin-left: 50px;
    margin-bottom: 50px;
  }
  .alignleft {
    float: left;
    margin-right: 50px;
    margin-bottom: 50px;
  }
  ol,ul {
    margin-bottom: 20px;
    padding-left: 15px;
    li {
      padding: 5px 0;
    }
  }
  .galley {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
    img {
      &:first-child {
        grid-column: 1/3;
      }
    }
  }
}

footer.footer {
  background: #DBE6E5;
  padding: 40px 0 20px;
  margin-top: 60px;
  .menu-footer {
    display: flex;
    column-gap: 50px;
    list-style: none;
    margin-left: 80px;
    margin-top: 20px;
    a {
      color: #001B29;
      font-weight: 500;
      border-bottom: 1px solid transparent;
      &:hover {
        color: #3E5F5D;
        border-color: #3E5F5D;
      }
    }
  }
  .social-list {
    display: flex;
    column-gap: 8px;
    margin-top: 36px;
    a {
      display: inline-flex;
      width: 24px;
      height: 24px;
      &:hover {
        border-radius: 50%;
        opacity: .7;
        overflow: hidden;
        transform: scale(1.01);
      }
    }
  }
  .subscribe {
    display: grid;
    column-gap: 8px;
    grid-template-columns: 1.5fr 1fr;
  }
  .copy {
    text-align: center;
    font-size: 12px;
    margin-top: 30px;
  }
}

@import 'base/media';